import {BrowserRouter, HashRouter, Route, Switch, Router, createBrowserRouter, RouterProvider} from 'react-router-dom'

import Header from './components/Header/Header';
import Login from './components/Login/Login';
import ListArticle from './components/ListArticle/ListArticle';
import CreateArticle from './components/CreateArticle/CreateArticle';
import DetailArtikel from './components/DetailArtikel/DetailArtikel';



function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login/>,
    }
  ])
  const router2 = createBrowserRouter([
    {
      path: "/",
      element: <ListArticle/>,
    },
    {
      path: "/create",
      element: <CreateArticle/>
    },
    {
      path: "/article/:id",
      element: <DetailArtikel/>
    }
  ])

  console.log(window.localStorage.getItem('TokenBCI'));

  if (window.localStorage.getItem('TokenBCI') === null) {
    return <RouterProvider router={router} />
    
  }else{
    return <RouterProvider router={router2}/>
  }

  
}

export default App;
