import React, {Component} from "react";
import axios from "axios";

import './DetailArtikel.css'
class DetailArtikel extends Component{
    constructor(props){
        super(props);
        this.state = {
            artikelDetail:''
    }}

    componentDidMount(){
        this.getArticleById()
    }

    getArticleById = async() => {
        const id = window.location.pathname.split('/').slice(2).join()
        await axios.get(`https://api-article.bumicimanggisindah.com/artikel/${id}`)
        .then(res => {
            // console.log(res.data.content.length);
            this.setState({
                artikelDetail: res.data
            })
        })


        // console.log(id);

    }

    artikelRender = () => {
        const {artikelDetail} = this.state
        const d = new Date(artikelDetail.date)
        const d2 = d.toDateString()
        // console.log(d2);

        return(
            <section className="artikel" >

                <h1 className="artikel-title" >{artikelDetail.title}</h1>
                <time className="artikel-date" >{d2}</time>

                <img src={artikelDetail.url} className = "artikel-image" alt={artikelDetail.title} />

                <article className="box-artikel" dangerouslySetInnerHTML={{__html: artikelDetail.content}} >



                </article>
                

            </section>
        )


    }

    render(){
        const {artikelDetail} = this.state
        return(
            <main className="detail-artikel" >

                <section className="box" id="logo-box" >
                    <h1>BCI</h1>
                    <h2>Admin Dashboard</h2>
                </section>

                {this.artikelRender()}

                

            </main>
        )
    }

}

export default DetailArtikel;