import React, {Component} from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {convertFromHTML} from "draft-convert"
import DOMPurify from "dompurify";
import axios from 'axios'
import {redirect, Navigate} from 'react-router-dom'
import Swal from "sweetalert2";

import "./CreateArticle.css"

import Arrow from '../../Assets/Icon Arrow.png'
import FileImage from '../../Assets/Path 14.png'

class CreateArticle extends Component{

    constructor(props){
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            title: "",
            date: "",
            imageUpload: FileImage,
            asal: "<p>asd</p><p>asd</p>"
        }
    }

    onEditorStateChange = (editorState) => {
        // // const asd = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        // console.log(convertFromHTML(asd));
        this.setState({
          editorState,
        });
      };

      photoHandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2){
                this.setState({imageUpload: reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])

        
    }

    uploadData = (e) => {
        // e.preventDefault();
        const {title, date, editorState} = this.state
        const Image = this.image.files[0]
        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        

        const formData = new FormData();

        formData.append("title", title);
        formData.append("date", date);
        formData.append("file", Image)
        formData.append("content", content)

            axios.post("https://api-article.bumicimanggisindah.com/artikel", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(res => {
                // console.log(res.data.msg);
                Swal.fire({
                    title: `${res.data.msg}`,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      this.setState({
                        editorState: EditorState.createEmpty(),
                        title: "",
                        date: "",
                        imageUpload: FileImage,
                      })
                      window.history.back()
                    }
                  })
            }).catch(err => {
                console.log(err);
                Swal.fire({
                    title: `${err.response.data.msg}`,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                  })
                

            })

        ////////////////////////////////////////////////////////////////////////////////////////////////////STOP    

        // try {
        //     await axios.post("http://localhost:5000/artikel", formData, {
        //         headers: {
        //             "Content-Type": "multipart/form-data"
        //         }
        //     }).then(res => {
        //         console.log(res.data);
        //     })
        // } catch (error) {
        //     console.log(error.message);
        // }


        // console.log(Image);
        // console.log(title);
        // console.log(date);
        // console.log(content);
        // console.log(EditorState.createWithContent(convertFromHTML(asal)));
        
        
        // console.log(EditorState.push(editorState, convertFromHTML(asd)));

    }

    // coba = () => {
    //      console.log(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
    // }


    render(){
        const {editorState, imageUpload,title, date} = this.state
        // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        return(
            <main>

                <img src={Arrow} className = "back-arrow" onClick={() => {window.history.back()}} />
                <h1 className="title-create" >Create New Article</h1>

                <section className="form-create" >

                    <label className="label-form" >Title: <input value={title} type="text" placeholder="Title Here" id="title-input" className="input-form" onChange={(e) => this.setState({title: e.target.value})} /></label>
                    <label className="label-form" >Date: <input value={date}  type="date" placeholder="Title Here" className="input-form" onChange={(e) => this.setState({date: e.target.value})} /></label>
                    <label className="label-form" >Image: 
                    <input
                                            type = "file"
                                            ref = {input => this.image = input}
                                            style = {{display : "none"}}
                                            onChange = {this.photoHandler}
                                            accept = "image/*"
                                        />
                                        <img className="img-artikel-create" src={imageUpload} />
                                        <button className = "pick-file btn"  onClick = {() => this.image.click()} >Pilih File</button>
                    </label>
                    <label className="label-form" id="label-editor" >Artikel: 
                    <Editor
                        editorState={editorState} 
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                        editorStyle={{
                            border: "1px black solid",
                            height: "400px",
                            overflow:"scroll",
                            marginBottom: "10px"
                        }}
                         />
                    </label>
                    <button className="upload-button" onClick={this.uploadData} >Upload</button>
                </section>

                {/* display from raw HTML */}
                {/* <section dangerouslySetInnerHTML={{__html: this.state.asal}} >
                    
                </section> */}

            </main>
        )
    }
    

}

export default CreateArticle;