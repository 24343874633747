import React, {Component} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import "./ListArticle.css"

class ListArticle extends Component{

    constructor(props){
        super(props);
        this.state = {
            artikelList:[]
    }}

    componentDidMount(){
        this.getArtikel()
    }

    getArtikel = async() => {
        await axios.get("https://api-article.bumicimanggisindah.com/artikel")
        .then(res => {
            // console.log(res.data);
            this.setState({
                artikelList: res.data
            })
        }).catch(err => {
            // console.log(err);
        })
    }

    deleteArtikel = async(id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                 axios.delete(`https://api-article.bumicimanggisindah.com/artikel/${id}`)
                .then(res => {
                    // console.log(res.data);
                    Swal.fire(
                        'Deleted!',
                        'Article has been deleted.',
                        'success'
                      )
                    this.getArtikel()
                }).catch(err => {
                    // console.log(err);
                    Swal.fire({
                        title: `${err.response.data.msg}`,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false
                      })
                })
              
            }
          })
        // await axios.delete(`http://localhost:5000/artikel/${id}`)
        // .then(res => {
        //     console.log(res.data);
        //     this.getArtikel()
        // }).catch(err => {
        //     console.log(err);
        // })
    }

    renderList = () => {
        return this.state.artikelList.map(item => {
            
            return(
                <div  className = "text-link" >
                    <Link to= {`article/${item.id}`} className = "card" >
                    <div  className = "card-content" >
                    <img src = {item.url} />
                    </div>
                    <label className = "title" >{item.title.split(' ').slice(0,7).concat('...').join(" ")}</label>
                    <p className = "category" >{item.date}</p>
                    
                </Link>
                <button className="delete-button" onClick={() => this.deleteArtikel(item.id)} >Delete</button>
                </div>
                
                
                    // <div className = " card col-5 m-3 border border-5 border-dark card">
                    //     <img className = " card-img-top mt-2" src = {`http://localhost:1993/getproduct/image/${item.product_image}`} width = "200" height = "200"/>
                    //     <h5 className = "card-title">{item.name_product}</h5>
                    //     <p className = "card-text">Rp.{item.price.toLocaleString('IN')}</p>
                    //     <p className = "card-text">Category: {item.category_name}</p>
                    //     <Link to = {'/productdetail/' + item.id}>
                    //         <button  className = "btn btn-dark mb-2" >Detail</button>
                    //     </Link>
                    // </div>

                    
            )
        })
    }

    logout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to logout?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
          }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('TokenBCI')
                window.location.reload();
            }
          })
        
    }

    render(){
        // const long = "Artikel - Mengendalikan harga demi keterjangkauan pemenuhan pangan"
        // console.log(long.split(' ').slice(0,7).concat('...').join(" "));
        return(
            <main className="list-article" >
                
                <section className="box" id="logo-box" >
                    <h1>BCI</h1>
                    <h2>Admin Dashboard</h2>
                </section>

                <section className="nav" >

                    <Link to="/create" className="link-name" >Create Article</Link>
                    <button onClick={this.logout} className="link-name" id="button-logout" >Logout</button>

                </section>

                <section className = "product-list" >
                        {this.renderList()}
                </section>

            </main>
        )
    }

}

export default ListArticle;